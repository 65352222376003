import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token = new BehaviorSubject(null);

  public userRoles = new BehaviorSubject(null);

  private getTokenFromLocalStorage() {

    const token = localStorage.getItem('AuthToken');

    if (token) {
      return token;
    }

    return null;

  }

  private saveTokenToLocalStorage(token) {

    localStorage.setItem('AuthToken', token);
  }

  private removeTokenFromLocalStorage() {

    localStorage.removeItem('AuthToken');
  }

  public getToken() {

    return this.token.getValue();
  }

  public checkIfSignedIn() {

    if (this.getToken()) {
      return true;
    }

    return false;
  }

  public signIn(token) {

    this.token.next(token);
    this.saveTokenToLocalStorage(token);
    this.router.navigate(['']);
  }

  signOut() {

    this.removeTokenFromLocalStorage();
    this.token.next(null);
    this.router.navigate(['/auth/sign-in']);
    location.reload();
  }

  signOutAccessForbidden() {

    this.removeTokenFromLocalStorage();
    this.token.next(null);
    this.router.navigate(['/auth/sign-in'],
      {
        queryParams: {forbiddenAccessSignOut: true}
      });
  }

  public getAdminProfile() {

    return this.http.get(API_URL + '/admin/users/me');
  }

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) {

    this.token.next(this.getTokenFromLocalStorage());
  }
}
