import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService, private toastr: ToastrService, private router: Router) {
  }

  public getAuthorizedRequest(req: HttpRequest<any>) {

    const realToken = this.authService.getToken();
    let tokenToHeader = '';

    if (realToken !== null) {
      tokenToHeader = realToken;
    }

    return req.clone({
      setHeaders: {
        Authorization: tokenToHeader
      }
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(this.getAuthorizedRequest(req)).pipe(
      catchError((err, caught) => {

        if (err instanceof  HttpErrorResponse && (err.status === 400 || err.status === 401 || err.status === 402)) {
          if (!err.url.includes('/auth/')) {
            this.authService.signOutAccessForbidden();
          }
        }

        if (err instanceof  HttpErrorResponse && (err.status === 403)) {
          this.toastr.error('Nie posiadasz uprawnień do wykonania tej operacji');
        }

        return Observable.throw(err);
      })
    );
  }


}
