import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
import { MainGuard } from './auth/main.guard';
import { EntryGuard } from './auth/entry.guard';


import {
  ToastrModule,
  ToastNoAnimation,
  ToastNoAnimationModule
} from 'ngx-toastr';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AuthModule,
    AppRoutingModule,
    HttpClientModule,
    ToastNoAnimationModule.forRoot()
  ],
  providers: [MainGuard, EntryGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
