export const environment = {
  production: true
};

// export const API_URL = 'http://localhost:8080';
export const API_URL = 'https://ecapi.windydev.pl';

export const APPLICATION = {
  ID: 1
};

// [MODULE] - ItemCategory
export const ITEM_CATEGORY = {
  ROOT_CATEGORY_ID: 1
};
