import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  user = {};

  constructor(private authService: AuthService) {
  }

  signOut() {

    this.authService.signOut();
  }

  ngOnInit() {

    this.authService.getAdminProfile().subscribe(user => {
      this.authService.userRoles.next(user['roles']);
      this.user = user;
    });
  }

}
