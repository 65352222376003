import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EntryGuard } from './auth/entry.guard';
import { MainGuard } from './auth/main.guard';


const routes: Routes = [
  {
    path: 'auth',
    canLoad: [
      EntryGuard
    ],
    loadChildren: () => import('./entry/entry.module').then(m => m.EntryModule)
  },
  {
    path: '',
    canLoad: [
      MainGuard
    ],
    loadChildren: () => import('./main/main.module').then(m => m.MainModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
